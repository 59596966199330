<template>
  <div class="top_panel" :class="{ 'top_panel-full': isOpen }">
    <div class="top_panel--container">
      <scrollactive class="top_panel--menu">
        <div class="top_panel--item top_panel--item-top">
          <a class="top_panel--link" href="/">
            <div class="top_panel--logo"></div>
          </a>
          <div class="top_panel--item-button" :class="{ 'top_panel--item-button-open': isOpen }" v-on:click="toggleMenu()">
            <div></div>
          </div>
        </div>
        <a class="top_panel--item scrollactive-item" href="/#about" v-on:click="itemClicked">
          <div class="top_panel--button">O nás</div>
        </a>
        <a class="top_panel--item scrollactive-item" href="/#services" v-on:click="itemClicked">
          <div class="top_panel--button">Služby</div>
        </a>
        <a class="top_panel--item top_panel--item-gap scrollactive-item" href="/#faq" v-on:click="itemClicked">
          <div class="top_panel--button">FAQ</div>
        </a>
        <div class="top_panel--item">
          <a class="top_panel--button top_panel--button-large" href="/contact">Kontakt</a>
        </div>
      </scrollactive>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopPanel',
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleMenu () {
      this.isOpen === true ? this.closeMenu() : this.openMenu()
    },
    openMenu () {
      this.isOpen = true
      document.body.style.overflow = 'hidden'
    },
    closeMenu () {
      this.isOpen = false
      document.body.style.overflow = 'auto'
    },
    itemClicked () {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      } else {
        this.closeMenu()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './top_panel';
</style>
