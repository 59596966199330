import * as types from './mutation_types'
import api from '../../api'

export const sendPhone = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    commit(types.SEND_PHONE_REQUEST)

    api.post('/phone', {
      phone: payload.phone
    }).then(res => {
      commit(types.SEND_PHONE_SUCCESS, res.data)
      resolve(res)
    }).catch(err => {
      commit(types.SEND_PHONE_FAILURE, err)
      reject(err)
    })
  })
}

export const sendForm = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    commit(types.SEND_FORM_REQUEST)

    api.post('/form', {
      name: payload.name,
      phone: payload.phone,
      email: payload.email,
      message: payload.message
    }).then(res => {
      commit(types.SEND_FORM_SUCCESS, res.data)
      resolve(res)
    }).catch(err => {
      commit(types.SEND_FORM_FAILURE, err)
      reject(err)
    })
  })
}
