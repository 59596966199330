<template>
  <div class="bottom_panel">
    <div class="bottom_panel--container">
      <div class="bottom_panel--links">
        <a class="bottom_panel--link" href="/terms">Ochrana Osobních Údajů</a>
      </div>
      <div class="bottom_panel--info">Henry & Holz Ltd | Reg. No.: 11799912 | Kemp House, 160 City Road, EC1V 2NX, Londýn, Spojené Království | © 2020 ThinkTherapy</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomPanel'
}
</script>

<style lang="scss" scoped>
  @import 'bottom_panel';
</style>
