<template>
  <div class="question">
    <div class="question--container" v-on:click="toggleAnswer">
      <div class="question--circle"></div>
      <div class="question--content">
        <div class="question--title">{{ title }}</div>
        <div class="question--answer" v-if="isVisible">
          <div class="question--answer_section" v-for="(item, index) in answer" v-bind:key="index">
            <div class="question--answer_title" v-if="item.title">{{ item.title }}</div>
            <div class="question--answer_text" v-if="item.text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Question',
  data () {
    return {
      isVisible: false
    }
  },
  props: ['title', 'answer'],
  methods: {
    toggleAnswer () {
      this.isVisible = !this.isVisible
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './question';
</style>
