<template>
  <div class="home">
    <top-panel></top-panel>
    <!-- Intro Section -->
    <div class="home--section home--section-intro">
      <div class="home--section_container">
        <div class="home--intro_title">
          <div class="home--title">Získejte Odbornou Radu & Pomoc Psychologa</div>
        </div>
        <div class="home--intro_description">
          <div class="home--title home--title-small">Napište našemu terapeutovi co vás trápí, odpověď obdržíte co nejdříve.</div>
          <div class="home--title home--title-small home--title-small-gray">Nebo nám nechte své telefoní číslo a my se Vám ozveme.</div>
        </div>
        <div class="home--intro_form_container">
          <form class="home--intro_form" autocomplete="off" novalidate v-on:submit.prevent="sendPhoneNumberTop">
            <div class="home--intro_form_field">
              <div class="home--intro_form_code">+420</div>
              <input class="home--intro_form_input" inputmode="numeric" placeholder="Vaše telefoní číslo" v-model="phoneNumberInputTop">
            </div>
            <button class="home--intro_form_button">Zavolat</button>
            <input class="home--intro_form_submit" type="submit">
          </form>
          <div class="home--title home--title-micro">Stisknutím tlačítka souhlasíte se <a class="home--intro_link" href="/terms">zpracováním osobních údajů</a>.</div>
        </div>
      </div>
    </div>
    <!-- Image Section -->
    <div class="home--image_section home--section">
      <img class="home--section_image" src="./intro.jpg">
    </div>
    <div class="home--section home--section-dark" id="about">
      <div class="home--section_container">
        <div class="home--about_title">
          <div class="home--title">Pár Slov o Nás</div>
        </div>
        <div class="home--about_text">Již více než 6 let působíme v Británii kde jsme se naučili poskytovat plný servis v oblasti psychologického poradenství dospělým klientům. Našim hlavním cílem je Vám pomoci co nejrychleji, narozdíl od běžné praxe v ČR kde jak sami víte Vás do měsíce nikdo neobjedná. Z 95% přijedeme k vám domů kde se cítíte komfortně nebo na neutrální půdu do kavárny, čímž se vyhnete ordinacím, kde můžete potkat lidi o které nestojíte. Absolutní diskrétnost je naší nejvyšší prioritou. Naše moto zní: To nejcenější co máte je soukromí, které vám pomůžeme chránit.</div>
      </div>
    </div>
    <!-- Services Section -->
    <div class="home--section" id="services">
      <div class="home--section_container">
        <div class="home--description_title">
          <div class="home--title">Vaše Cesta ke<br>Klidnému Životu</div>
        </div>
        <div class="home--description_tiles_holder">
          <div class="home--description_tile">
            <div class="home--description_tile_container">
              <div class="home--description_tile_title">Manželské Problémy</div>
              <div class="home--description_tile_text">Po rozvodu nebo nevěra; šikana v manželství; domácí násilí; neshody s vašimi rodiči při společném bydlení; problémy s neposlušnými dětmi.</div>
            </div>
          </div>
          <div class="home--description_tile">
            <div class="home--description_tile_container">
              <div class="home--description_tile_title">Rodinné Tragédie</div>
              <div class="home--description_tile_text">Úmrtí jednoho z manželů nebo rodičů; úmrtí dítěte; kteréhokoli člena rodiny a nezapomínáme ani na vaše domácí mazlíčky.</div>
            </div>
          </div>
          <div class="home--description_tile">
            <div class="home--description_tile_container">
              <div class="home--description_tile_title">Problematika Závislostí</div>
              <div class="home--description_tile_text">Drogy nebo hazard; alkohol; střídání partnerů; internet; sociální sítě a jiná média; telefon; farmaka; káva a čokoláda; nakupování.</div>
            </div>
          </div>
          <div class="home--description_tile">
            <div class="home--description_tile_container">
              <div class="home--description_tile_title">Fobie</div>
              <div class="home--description_tile_text">Strach ze stísněných a uzavřených prostor; sociální fóbie; akrofobie; aerofobie; pyrofobie; agyrofobie; aichmofobie; keraunofobie.</div>
            </div>
          </div>
          <div class="home--description_tile">
            <div class="home--description_tile_container">
              <div class="home--description_tile_title">Úzkostné Poruchy</div>
              <div class="home--description_tile_text">Řadíme k neurotickým poruchám zahrnujících stres; několik forem nadměrné úzkosti a strachu. Jsou značně vysilující nebo chronické.</div>
            </div>
          </div>
          <div class="home--description_tile">
            <div class="home--description_tile_container">
              <div class="home--description_tile_title">Syndrom Vyhoření</div>
              <div class="home--description_tile_text">Poruchy spánku; totální vyčerpání organismu; předcházející příčínou bývá nezvládnutý workoholismus; způsobuje rozpad manželství.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home--section home--section-border" id="faq">
      <div class="home--section_container">
        <div class="home--faq_title">
          <div class="home--title">FAQ</div>
        </div>
        <div class="home--faq_container">
          <question title="Hradí vaše služby zdravotní pojišťovna?" :answer="[{ text: 'Bohužel je nám to velice líto, nicméně byť je ČR v EU tyto nadstandardní služby tuzemské zdravotní pojišťovny nehradí.' }]"></question>
          <question title="Kolik stojí jedna hodina konzultace?" :answer="[{ text: 'Naše hodinová sazba je 2000,- Kč + cestovné na kterém se individuálně dohodneme.' }, { text: 'Jen pro vaši informaci, veřejný psycholog obdrží tabulkově od pojišťovny 1650,- za 45 minut, které Vám věnuje na čež již posledních 15 minut sleduje své hodinky. Rozdíl v našich službách je naprosto nesrovnatelný.'}]"></question>
          <question title="Jakou praxi mají vaši psychologové?" :answer="[{ text: 'Většina našich psychologů dosáhla svého vzdělání na britských univerzitách.' }]"></question>
          <question title="Je možná spolupráce formou rodinného psychologa?" :answer="[{ text: 'Samozřejmě, že ano. Tuto alternativu spíše vítáme, jelikož jsme na ni ze zahraničí zvyklí. Nejedná se u nás o nic nového, jelikož již za prvorepublikové éry fungovaly rodinní lékaři, psychologové apod.' }]"></question>
          <question title="Můžeme se dohodnout na pravidelných konzultacích?" :answer="[{ text: 'Ano určitě, je to pro nás přínosem protože si můžeme Vaše předplacené konzultace lépe naplánovat a vyjít tak vstříc časově Vám a také ostatním klientům.' }]"></question>
          <question title="Pracujete i s dětmi?" :answer="[{ text: 'Je nám líto, ale naše specializace je cílená výlučně na dospělé a mladistvé. OSN definuje od roku 1981 mladistvé jako osobu mezi 15. a 24. rokem života.' }]"></question>
          <question title="Jak rychle mne můžete navštívit?" :answer="[{ text: 'Do 24 hodin se s Vámi telefonicky spojíme a dohodneme se na termínu.' }]"></question>
          <question title="Ve kterých krajích působíte?" :answer="[{ text: 'Naše služby poskytujeme převážně v nasledujících krajích: Jihomoravský, Zlínský, Olomoucký, Moravskoslezský, Pardubický, Vysočina.' }]"></question>
        </div>
      </div>
    </div>
    <!-- Request Section -->
    <div class="home--section home--section-dark">
      <div class="home--section_container">
        <div class="home--request_title">
          <div class="home--title">Zavolejme si</div>
        </div>
        <div class="home--request_description">
          <div class="home--title home--title-small home--title-small-gray">Nechte nám své telefoní číslo a my se Vám ozveme.</div>
        </div>
        <div class="home--intro_form_container">
          <form class="home--intro_form" autocomplete="off" novalidate v-on:submit.prevent="sendPhoneNumberBottom">
            <div class="home--intro_form_field">
              <div class="home--intro_form_code">+420</div>
              <input class="home--intro_form_input" inputmode="numeric" placeholder="Vaše telefoní číslo" v-model="phoneNumberInputBottom">
            </div>
            <button class="home--intro_form_button">Zavolat</button>
            <input class="home--intro_form_submit" type="submit">
          </form>
          <div class="home--title home--title-micro">Stisknutím tlačítka souhlasíte se <a class="home--intro_link" href="/terms">zpracováním osobních údajů</a>.</div>
        </div>
      </div>
    </div>
    <bottom-panel></bottom-panel>
  </div>
</template>

<script>
import TopPanel from '@/components/top_panel/TopPanel'
import Question from '@/components/question/Question'
import BottomPanel from '@/components/bottom_panel/BottomPanel'

export default {
  name: 'home',
  data () {
    return {
      phoneNumberInputTop: '',
      phoneNumberInputBottom: ''
    }
  },
  components: {
    TopPanel,
    Question,
    BottomPanel
  },
  methods: {
    sendPhoneNumberTop () {
      this.$store.dispatch('sendPhone', { phone: this.phoneNumberInputTop })
    },
    sendPhoneNumberBottom () {
      this.$store.dispatch('sendPhone', { phone: this.phoneNumberInputBottom })
    }
  }
}
</script>

<style lang="scss">
  @import 'home';
</style>
