import * as types from './mutation_types'

export const mutations = {
  // Phone form
  [types.SEND_PHONE_REQUEST] (state) {
  },
  [types.SEND_PHONE_SUCCESS] (state, payload) {
  },
  [types.SEND_PHONE_FAILURE] (state, err) {
  },

  // Contact form
  [types.SEND_FORM_REQUEST] (state) {
  },
  [types.SEND_FORM_SUCCESS] (state, payload) {
  },
  [types.SEND_FORM_FAILURE] (state, err) {
  }
}
